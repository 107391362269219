import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { Routes } from '../types/global.type';
import { useAnalysesByTrialIdQuery } from '../redux/services/analysis';
import Spinner from '../components/global/Spinner';
import styles from './analyze-trial.module.scss';

const DownloadReport = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const trialId = searchParams.get('trial-id');
  const analysisId = searchParams.get('analysis-id');
  const { data: analysisResponse, isLoading } = useAnalysesByTrialIdQuery(
    trialId || '',
    {
      skip: !trialId || !isLoggedIn,
    },
  );

  useEffect(() => {
    // If not logged in, redirect to login with return URL
    if (!isLoggedIn) {
      const returnUrl = `${Routes.downloadReport}?trial-id=${trialId}&analysis-id=${analysisId}`;
      navigate(`${Routes.login}?returnUrl=${encodeURIComponent(returnUrl)}`);
      return;
    }

    if (!trialId || !analysisId) {
      navigate(Routes.analyzeTrial);
      return;
    }

    // Once we have the analysis data and not loading
    if (!isLoading && analysisResponse) {
      const analysis = analysisResponse.data.find(
        (a) => a.analysis_id === analysisId,
      );
      if (analysis?.report?.report_pdf?.signed_url) {
        // Open the PDF in a new tab with noopener
        window.open(
          analysis.report.report_pdf.signed_url,
          '_blank',
          'noopener',
        );
        // After opening PDF, redirect back to analysis page
        navigate(`${Routes.analyzeTrial}?trial-id=${trialId}`);
      } else {
        // If no matching analysis or no report, redirect to analyze trial page
        navigate(`${Routes.analyzeTrial}?trial-id=${trialId}`);
      }
    }
  }, [trialId, analysisId, analysisResponse, isLoading, isLoggedIn, navigate]);

  if (isLoading) {
    return (
      <div className={styles['analysis-landing-content-loading']}>
        <Spinner color="brand" size="48px" />
        <span>Preparing your report for viewing...</span>
      </div>
    );
  }

  return null;
};

export default DownloadReport;
