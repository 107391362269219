import { useCallback, useEffect, useState } from 'react';
import { updateYieldDataFlowStep } from '../../../redux/slices/analyzeTrial';
import { StepContent } from '../../step-flow/CreateStepContent';
import {
  FlowStep,
  RepsToRemoveStepDefinition,
  ShapefileColumnMapStepDefinition,
  UploadAndPreviewShapefileStepDefinition,
} from '../../../types/stepFlow.type';
import * as Yup from 'yup';
import { useAppSelector } from '../../../redux/hooks';
import styles from './yieldFlow.module.scss';
import { titleCase } from '../../../utils/helpers/capitalizationUtils';
import UploadAndPreviewShapefileStep from '../../step-flow/UploadAndPreviewShapefileStep';
import ShapefileColumnMapStep, {
  getShapefileColumnMapFields,
  getShapefileColumnMapInitialValues,
  getShapefileColumnMapValidator,
} from '../ShapefileColumnMapStep';
import { fileValidator } from '../../../validators/createTrialSchemas';
import YieldSummaryStep from '../YieldSummaryStep';
import { useSearchParams } from 'react-router-dom';
import { DateStep } from 'components/step-flow/DateStep';
import { useGCPFiles } from '../ShapefileImagePreview';
import { YieldSubmitStructure } from '../../../types/analyzeTrial.type';
import RepsToRemoveStep from '../RepsToRemoveStep';
import { useFetchDesignImageQuery } from '../../../redux/services/analysis';
import loadingImage from '../../../assets/images/loading-image.png';

const YieldFlow = () => {
  const trialDesign = useAppSelector((state) => state.analyzeTrial.trialDesign);
  const [searchParams] = useSearchParams();
  const [savedYieldData, setSavedYieldData] = useState<any>(null);
  const [fieldResultId, setFieldResultId] = useState<string | null>(
    searchParams.get('id'),
  );
  const fieldResults = useAppSelector(
    (state) => state.analyzeTrial.fieldResults,
  );

  const [fieldResultsType] = useState('yield');
  const yieldInProgress = useAppSelector(
    (state) => state.analyzeTrial.yieldDataFlow.inProgress,
  ) as YieldSubmitStructure;

  const MatchFieldResultId = useCallback(() => {
    for (let i = 0; i < fieldResults.length; i++) {
      if (fieldResults[i].field_result_id === fieldResultId) {
        return fieldResults[i].yield_data;
      }
    }
    return null;
  }, [fieldResults, fieldResultId]);

  const { data: designImage } = useFetchDesignImageQuery(
    trialDesign?.shapefile_preview_url as string,
    {
      skip: !trialDesign?.shapefile_preview_url,
    },
  );

  useEffect(() => {
    if (fieldResultId) {
      setSavedYieldData(MatchFieldResultId());
    }
  }, [fieldResultId, MatchFieldResultId]);

  const yieldFlowSteps: FlowStep[] = [
    {
      container: UploadAndPreviewShapefileStep,
      heading: `${titleCase(fieldResultsType)} data`,
      props: {
        currentShapefile: savedYieldData?.current_shapefile,
        fieldBoundaryUrl: trialDesign.field_boundary_file_url,
        fieldResultId: fieldResultId,
        formElements: [],
        imageUrl:
          savedYieldData?.raw_preview_image?.signed_url ||
          designImage?.image ||
          loadingImage,
        initialValues: {
          yield_shapefiles: yieldInProgress.yield_shapefiles || [],
        },
        setFieldResultId: setFieldResultId,
        shapefileType: fieldResultsType,
        trialBoundaryUrl: trialDesign.trial_boundary_file_url,
        trialId: trialDesign.id,
        uniqueKey: 'yield_shapefiles',
        validationSchema: savedYieldData?.current_shapefile
          ? null
          : Yup.object({
              yield_shapefiles: fileValidator(fieldResultsType),
            }),
      },
    } as UploadAndPreviewShapefileStepDefinition,
    {
      container: ShapefileColumnMapStep,
      heading: `Identify ${titleCase(fieldResultsType)} data columns`,
      props: {
        fieldBoundaryUrl: trialDesign.field_boundary_file_url,
        fieldResultId: fieldResultId,
        formElements: getShapefileColumnMapFields({
          shapefileType: 'yield',
          shapefileGeometry: savedYieldData?.current_shapefile?.geometry_type,
          shapefileKeyColumnLabel: `${titleCase(fieldResultsType)} rate`,
          savedData: savedYieldData,
        }),
        imageUrl:
          savedYieldData?.scaled_preview_image?.signed_url ||
          savedYieldData?.raw_preview_image?.signed_url,
        initialValues: getShapefileColumnMapInitialValues({
          savedData: savedYieldData,
          shapefileGeometry: savedYieldData?.current_shapefile?.geometry_type,
          shapefileType: 'yield',
          trialUnit: savedYieldData?.current_shapefile?.rate_units || 'bu/ac',
        }),
        setFieldResultId: setFieldResultId,
        shapefileGeometry: savedYieldData?.current_shapefile?.geometry_type,
        shapefileType: fieldResultsType,
        trialBoundaryUrl: trialDesign.trial_boundary_file_url,
        trialId: trialDesign.id,
        uniqueKey: 'yield_column_map',
        uploadedFiles: yieldInProgress.yield_shapefiles || [],
        useGCPFiles: useGCPFiles(
          savedYieldData,
          yieldInProgress.yield_shapefiles,
          'ShapefileColumnMapStep',
        ),
        validationSchema: getShapefileColumnMapValidator({
          shapefileGeometry: savedYieldData?.current_shapefile?.geometry_type,
          shapefileType: 'yield',
          trialUnit: fieldResults[0]?.as_applied_data?.rate_units
            ? fieldResults[0].as_applied_data.rate_units
            : trialDesign?.application_units ?? 'gal',
        }),
      },
    } as ShapefileColumnMapStepDefinition,
    {
      container: RepsToRemoveStep,
      heading: `${titleCase(fieldResultsType)} data quality control`,
      props: {
        fieldBoundaryUrl: trialDesign.field_boundary_file_url,
        trialBoundaryUrl: trialDesign.trial_boundary_file_url,
        fieldResultId: fieldResultId,
        imageUrl:
          savedYieldData?.scaled_with_plots_preview_image?.signed_url ||
          savedYieldData?.scaled_preview_image?.signed_url ||
          savedYieldData?.raw_preview_image?.signed_url,
        inProgressExecution: yieldInProgress,
        prescriptionParams:
          trialDesign.treatment_file_url !== null
            ? { prescriptionUrl: trialDesign.treatment_file_url }
            : {
                transactionId: trialDesign.transaction_id,
                fieldName: trialDesign.field.field_name,
              },
        savedData: savedYieldData,
        setFieldResultId: setFieldResultId,
        shapefileType: 'yield',
        trialId: trialDesign.id,
        trialType: trialDesign.planning_trial_type,
        uniqueKey: 'yield_reps_to_remove',
        uploadedFiles: yieldInProgress.yield_shapefiles || [],
        useGCPFiles: useGCPFiles(
          savedYieldData,
          yieldInProgress.yield_shapefiles,
          'RepsToRemoveStep',
        ),
      },
    } as RepsToRemoveStepDefinition,
    {
      container: DateStep,
      heading: `Harvest Date`,
      props: {
        currentShapefile: savedYieldData?.current_shapefile,
        dateFieldLabel: 'Harvest Date',
        dateFieldName: 'harvest_date',
        initialDate:
          savedYieldData?.harvest_date ||
          yieldInProgress?.harvest_date ||
          undefined,
      },
    },
    {
      container: YieldSummaryStep,
      heading: `${titleCase(fieldResultsType)}: Review and Save`,
      props: {
        currentShapefile: savedYieldData?.current_shapefile,
        fieldResultId: fieldResultId,
        imageUrl:
          savedYieldData?.scaled_with_plots_preview_image?.signed_url ||
          savedYieldData?.scaled_preview_image?.signed_url ||
          savedYieldData?.raw_preview_image?.signed_url,
        inProgressExecution: yieldInProgress,
        setSavedData: setSavedYieldData,
        shapefileGeometry: savedYieldData?.current_shapefile?.geometry_type,
        shapefileType: titleCase(fieldResultsType),
        trialId: trialDesign.id,
        uniqueKey: 'yield_summary',
      },
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const { heading, subheading, container, props } = yieldFlowSteps[activeIndex];

  if (Object.keys(trialDesign).length === 0) {
    return (
      <div
        data-testid="yield-flow-trial-design-missing-error"
        className={styles['yield-flow-error']}
      >
        <p>
          <b>The current page was loaded without a trial design.</b>
        </p>
        <p>
          This is probably because the URL was loaded directly, rather than from
          an Analysis & Results page. Please navigate from your list of field
          trials to view the analysis and results for a specific trial; from
          there you may click "Edit Yield Data" or "Upload Yield Data" to load
          load this page.
        </p>
        <p>
          If you came here from a link elsewhere on the site, please contact
          FarmTest for assistance.
        </p>
      </div>
    );
  }

  return (
    <StepContent
      heading={heading}
      subheading={subheading}
      superheading={`Analyze trial`}
      Container={container}
      props={props}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      updateStateFunction={updateYieldDataFlowStep}
      stateSelector={(state) => state.analyzeTrial.yieldDataFlow}
      flowSteps={yieldFlowSteps}
    />
  );
};

export default YieldFlow;
