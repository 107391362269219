import React from 'react';

export enum Routes {
  home = '/',
  login = '/login',
  signUp = '/create-account',
  resetPassword = '/reset-password',
  forgotPassword = '/forgot-password',
  verifyAccount = '/verify-account',
  createTrial = '/create-trial',
  fieldTrials = '/field-trials',
  purchaseTrial = '/purchase-trial',
  editAccount = '/edit-account',
  tryFarmtest = '/create-trial?guest=true',
  analyzeTrial = '/analyze-trial',
  asAppliedFlow = '/analyze-trial/as-applied-flow',
  yieldDataFlow = '/analyze-trial/yield-data-flow',
  analysisSetupFlow = '/analyze-trial/analysis-setup-flow',
  noApplicationDataFlow = '/analyze-trial/no-application-data-flow',
  downloadReport = '/download-report',
  blog = 'https://farmtest.ag/blog',
  product = 'https://farmtest.ag/product',
  about = 'https://farmtest.ag/about',
  contact = 'https://farmtest.ag/contact',
  tier = 'https://farmtest.ag/tiers',
}

export interface APIErrorResponse {
  msg: string | { error: string };
  error?: string | { error: string };
}

export interface DefaultAPIResponse<DataType = void> extends APIErrorResponse {
  data: DataType;
}

export type StorageMethod = 'local' | 'session';
export type CheckoutType = String;

export interface FormProps {
  loading: boolean;
  error: string;
  successMsg?: React.ReactNode;
}

export interface DrawerNavLink {
  title: string;
  to: Routes;
}

export interface FileTypes {
  [key: string]: {
    description: string;
    accept: { [key: string]: string[] };
  };
}
