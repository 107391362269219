import { useEffect } from 'react';
import { authRoutes } from 'utils/auth';
import {
  Route,
  Routes as BrowserRoutes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Routes } from 'types/global.type';
import { useAppDispatch } from 'redux/hooks';
import { redirectToCheckout } from 'utils/createTrial';
import { clearTrialData } from 'redux/slices/createTrial';
import { FEATURE_FLAG_ANALYSIS_UI } from '../utils/config';
import HomePage from 'pages/home';
import useAuth from 'hooks/useAuth';
import LoginPage from 'pages/login';
import SignUpPage from 'pages/sign-up';
import Layout from 'components/global/Layout';
import CreateTrialPage from 'pages/create-trial';
import FieldTrialsPage from 'pages/field-trials';
import EditAccountPage from 'pages/edit-account';
import ResetPasswordPage from 'pages/reset-password';
import VerifyAccountPage from 'pages/verify-account';
import ForgotPasswordPage from 'pages/forgot-password';
import AnalyzeTrialPage from '../pages/analyze-trial';
import AsAppliedFlow from '../components/analyze-trial/AsAppliedFlow';
import YieldFlow from '../components/analyze-trial/YieldFlow';
import useStorage from 'hooks/useStorage';
import 'styles/main.scss';
import AnalysisSetupFlow from '../components/analyze-trial/AnalysisSetupFlow';
import NoApplicationDataFlow from '../components/analyze-trial/NoApplicationDataFlow';
import DownloadReport from '../pages/download-report';

const App = () => {
  const { isLoggedIn, isGuest, activateGuestMode } = useAuth();
  const { removeStorageValue } = useStorage();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    removeStorageValue('createTrialResponse', 'session');
  }, [removeStorageValue]);

  useEffect(() => {
    if (
      location.pathname === Routes.createTrial &&
      (isGuest || searchParams.get('guest'))
    ) {
      return activateGuestMode();
    }

    if (typeof isLoggedIn !== 'boolean') return;

    const isAuthRoute = authRoutes.includes(location.pathname as Routes);
    const isProtectedRoute = !isAuthRoute;

    if (isLoggedIn && isAuthRoute) navigate(Routes.home);
    else if (
      !isLoggedIn &&
      isProtectedRoute &&
      location.pathname !== Routes.purchaseTrial
    ) {
      // Get the current search parameters
      const searchParams = new URLSearchParams(location.search);
      // Add the redirect parameter with decoded pathname
      searchParams.set('redirect', decodeURIComponent(location.pathname));
      // Redirect to login page with the updated query parameters
      const queryString = searchParams.toString();
      // alert(queryString);
      navigate(`${Routes.login}${queryString ? `?${queryString}` : ''}`);
    }
  }, [
    isLoggedIn,
    isGuest,
    location,
    activateGuestMode,
    navigate,
    searchParams,
  ]);

  useEffect(() => {
    if (location.pathname === Routes.purchaseTrial) {
      const key = searchParams.get('key');
      const sessionId = searchParams.get('sessionId');

      if (!key || !sessionId) {
        return window.close();
      }

      redirectToCheckout(key, sessionId);
    }

    //clear previous trial data in store
    if (location.pathname !== Routes.createTrial) {
      dispatch(clearTrialData());
      removeStorageValue('createTrialResponse', 'session');
    }
  }, [location, dispatch, removeStorageValue, searchParams]);

  if (
    typeof isLoggedIn !== 'boolean' ||
    location.pathname === Routes.purchaseTrial
  ) {
    return null;
  }

  return (
    <>
      <Layout>
        <BrowserRoutes>
          <Route path={Routes.home} element={<HomePage />} />
          <Route path={Routes.login} element={<LoginPage />} />
          <Route path={Routes.signUp} element={<SignUpPage />} />
          <Route path={Routes.verifyAccount} element={<VerifyAccountPage />} />
          <Route
            path={Routes.forgotPassword}
            element={<ForgotPasswordPage />}
          />
          <Route path={Routes.resetPassword} element={<ResetPasswordPage />} />
          <Route path={Routes.createTrial} element={<CreateTrialPage />} />
          <Route
            path={`${Routes.fieldTrials}/:id`}
            element={<FieldTrialsPage />}
          />
          <Route path={Routes.editAccount} element={<EditAccountPage />} />
          <Route path={Routes.downloadReport} element={<DownloadReport />} />
          {FEATURE_FLAG_ANALYSIS_UI && (
            <>
              <Route
                path={Routes.analyzeTrial}
                element={<AnalyzeTrialPage />}
              />
              <Route path={Routes.asAppliedFlow} element={<AsAppliedFlow />} />
              <Route path={Routes.yieldDataFlow} element={<YieldFlow />} />
              <Route
                path={Routes.analysisSetupFlow}
                element={<AnalysisSetupFlow />}
              />
              <Route
                path={Routes.noApplicationDataFlow}
                element={<NoApplicationDataFlow />}
              />
            </>
          )}
        </BrowserRoutes>
      </Layout>

      <section id="modals-container"></section>
      <section id="tooltips-container"></section>

      <Toaster />
    </>
  );
};

export default App;
