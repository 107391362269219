import { useFieldsQuery } from 'redux/services/workspace';
import WorkspaceContent from 'components/workspace/WorkspaceContent';
import { Column } from 'react-table';
import { ReactComponent as ExternalLinkIcon } from 'assets/vectors/external-link.svg';
import { Field } from '../../types/workspace.type';
import { Link } from 'react-router-dom';
import { Routes } from '../../types/global.type';
import Button from '../../components/global/Button';
import { useEffect } from 'react';

const fieldColumns: Column<Field>[] = [
  {
    Header: 'Operation',
    id: 'business_name',
    accessor: ({ business_name }) => business_name || '-',
  },
  { Header: 'Farm Name', accessor: ({ farm_name }) => farm_name || '-' },
  {
    Header: 'Field Name',
    accessor: 'field_name',
    Cell: ({ row, value }) => (
      <Link to={`${Routes.fieldTrials}/${row.original.id}`}>{value}</Link>
    ),
  },
  {
    Header: 'Area (acres)',
    accessor: (row) => row.trial?.field_area || '-',
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Button
        action="link"
        variant="icon"
        to={`${Routes.fieldTrials}/${value}`}
        target="_blank"
      >
        <ExternalLinkIcon />
      </Button>
    ),
  },
];

const HomeContainer = () => {
  const { data, isLoading, refetch } = useFieldsQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <WorkspaceContent
      data={data?.data || []}
      columns={fieldColumns}
      isLoading={isLoading}
      tableHeader={'Fields'}
      colsWithSort={['business_name', 'field_name']}
    />
  );
};

export default HomeContainer;
