import { useState } from 'react';
import Modal from 'components/global/Modal';
import Button from 'components/global/Button';
import styles from './purchaseChoiceModal.module.scss';
import Spinner from 'components/global/Spinner';
import { Routes } from '../../../types/global.type';
import { CheckoutType } from '../../../types/global.type';
import { Price } from 'types/payment.type';

interface PurchaseChoiceModalProps {
  isOpen: boolean;
  isLoading: boolean;
  optionsLoading: boolean;
  options: Price[];
  headerText: string;
  error: string | null;
  closeModal: () => void;
  confirmAction: (type: CheckoutType) => void;
}

const PurchaseChoiceModal = ({
  headerText,
  options,
  isOpen,
  isLoading,
  optionsLoading,
  error,
  closeModal,
  confirmAction,
}: PurchaseChoiceModalProps) => {
  // Step management for the multi-tier purchase modal
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  // Handles the selection of a category and moves to the next step
  const handleBuy = (option: any) => {
    setSelectedOption(option);
    setCurrentStep(2);
  };

  // Handles the confirmation of the purchase
  const handleConfirm = () => {
    confirmAction(selectedProduct.product_id);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickOutside={!isLoading ? closeModal : () => {}}
      className={styles['purchase-modal']}
      data-testid="purchase-choice-modal"
    >
      <h2
        className={styles['purchase-modal__header']}
        data-testid="purchase-choice-header"
      >
        {headerText}
      </h2>
      {/* Step 1: Choose a category */}
      {currentStep === 1 && (
        <div
          data-testid="purchase-options"
          className={styles['purchase-modal__purchase-options']}
        >
          {/* Display category options */}
          {optionsLoading ? (
            <Spinner data-testid="spinner" color="brand" />
          ) : (
            ''
          )}
          {options.map((option: any) => (
            <div
              className={styles['purchase-modal__purchase-options__option']}
              key={option.category}
            >
              <p>{option.category_description}</p>
              <h1>{option.category.toUpperCase()}</h1>
              <h2>{option.feature_list_header}</h2>
              <ul
                className={
                  styles['purchase-modal__purchase-options__option__list-items']
                }
                key={option.category}
              >
                {option.features.map((feature: string) => (
                  <li
                    key={feature}
                    className={
                      styles[
                        'purchase-modal__purchase-options__option__list-item'
                      ]
                    }
                  >
                    {feature}
                  </li>
                ))}
              </ul>
              {/* Button to select a category */}
              <Button
                className={
                  styles['purchase-modal__purchase-options__option__button']
                }
                variant="brand"
                useDefaultWidth={false}
                action="button"
                data-testid={option.category}
                onClick={() => handleBuy(option)}
                isLoading={isLoading}
              >
                Select {option.category}
              </Button>
            </div>
          ))}
        </div>
      )}
      {/* Step 2: Choose specific product/quantity for the selected category */}
      {currentStep === 2 && selectedOption && (
        <div
          data-testid="purchase-quantity"
          className={styles['purchase-modal__purchase-options']}
        >
          {selectedOption.items.map((option: any) => (
            <div
              key={option.product_id}
              onClick={() => setSelectedProduct(option)}
              data-testid={option.product_id}
              className={`${styles['purchase-modal__purchase-options__box']} ${
                selectedProduct?.product_id === option.product_id
                  ? styles['purchase-modal__purchase-options__box-selected']
                  : ''
              }`}
            >
              {option.price}
            </div>
          ))}
        </div>
      )}
      {error && (
        <p
          data-testid="choice-error"
          className={styles['purchase-modal__choice-error']}
        >
          {error}
        </p>
      )}
      {currentStep === 2 && (
        <div
          className={`${styles['purchase-modal__sub-footer']} ${
            isLoading || !selectedProduct?.product_id
              ? styles['purchase-modal__footer--disabled']
              : ''
          }`}
          data-testid="purchase-choice-sub-footer"
        >
          <Button
            variant="brand"
            useDefaultWidth={false}
            action="button"
            onClick={handleConfirm}
            data-testid="confirm-product-purchase"
            disabled={!selectedProduct?.product_id || isLoading}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </div>
      )}
      {/* Footer with navigation and additional links */}
      <div
        className={`${styles['purchase-modal__footer']} ${
          isLoading ? styles['purchase-modal__footer--disabled'] : ''
        }`}
        data-testid="purchase-choice-footer"
      >
        {!optionsLoading && (
          <>
            <span>
              <a href={Routes.tier} target="_blank" rel="noopenner noreferrer">
                Learn more about our different tiers.
              </a>{' '}
              Can't find what you're looking for?
              <a
                href={Routes.contact}
                target="_blank"
                rel="noopenner noreferrer"
              >
                {' '}
                Contact us
              </a>
            </span>
          </>
        )}
        <Button
          action="button"
          disabled={isLoading}
          data-testid="cancel-button"
          onClick={() => {
            setSelectedProduct(null);
            setCurrentStep(1);
            closeModal();
          }}
        >
          Cancel
        </Button>
        {currentStep === 2 && (
          <Button
            action="button"
            disabled={isLoading}
            onClick={() => {
              setCurrentStep(1);
              setSelectedProduct(null);
            }}
          >
            Back
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default PurchaseChoiceModal;
