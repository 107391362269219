import React, { useEffect, useState } from 'react';
import Modal from 'components/global/Modal';
import Button from 'components/global/Button';
import { FieldTrial } from 'types/workspace.type';
import styles from './copyTrialModal.module.scss';
import FormElement from 'components/global/FormElement';
import { Formik, Form } from 'formik';

interface CopyTrialModalProps {
  isOpen: boolean;
  isLoading: boolean;
  closeModal: () => void;
  trial: FieldTrial;
  onCreate: (newTrial: FieldTrial) => void;
  error: string | null;
}

const CopyTrialModal: React.FC<CopyTrialModalProps> = ({
  isOpen,
  isLoading,
  closeModal,
  trial,
  onCreate,
  error,
}) => {
  const [formValues, setFormValues] = useState({
    farm_name: '',
    field_name: '',
    business_name: '',
  });

  // Update form values whenever the trial prop changes
  useEffect(() => {
    if (trial?.field) {
      setFormValues({
        farm_name: trial.field.farm_name || '',
        field_name: trial.field.field_name || '',
        business_name: trial.field.business_name || '',
      });
    }
  }, [trial]);

  const handleCreate = (values: any) => {
    onCreate({
      ...trial,
      field: {
        ...trial.field,
        ...values,
      },
    });
    closeModal();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClickOutside={!isLoading ? closeModal : () => {}}
        className={styles['copy-trial-modal']}
      >
        <h2
          className={styles['copy-trial-modal__header']}
          data-testid="copy-trial-modal__header"
        >
          Copy Trial to New
        </h2>
        <p>
          {error && (
            <span
              className={styles['copy-trial-modal__error']}
              data-testid="copy-trial-modal__error"
            >
              {error}
            </span>
          )}
        </p>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values) => handleCreate(values)}
          data-testid="copy-trial-form"
        >
          <Form>
            <div
              data-testid="form-options"
              className={styles['copy-trial-modal__form-container']}
            >
              <FormElement
                name="business_name"
                type="text"
                label="Business Name"
              />
              <FormElement name="farm_name" type="text" label="Farm Name" />
              <FormElement name="field_name" type="text" label="Field Name" />
            </div>
            <div
              className={`${styles['copy-trial-modal__footer']} ${
                isLoading ? styles['copy-trial-modal__footer--disabled'] : ''
              }`}
            >
              <Button
                className={styles['copy-trial-modal__form-container__button']}
                action="button"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                className={styles['copy-trial-modal__form-container__button']}
                action="button"
                variant="brand"
                type="submit"
                isLoading={isLoading}
              >
                Copy
              </Button>
            </div>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CopyTrialModal;
