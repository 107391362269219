import FormAndImageStep from '../../step-flow/FormAndImageStep';
import {
  FormElementPropsType,
  ShapefileColumnMapStepProps,
} from '../../../types/stepFlow.type';
import getOptions from '../../../utils/helpers/getOptions';
import getOption from '../../../utils/helpers/getOption';
import { trialTypeOptions } from '../../../utils/createTrial';
import * as Yup from 'yup';
import { FieldData } from '../../../types/analyzeTrial.type';
import ShapefileImagePreview from '../ShapefileImagePreview';
import { useDebouncedCallback } from 'use-debounce';
import { useState, useRef } from 'react';
import { ShapefileColumnMapFields } from '../../../types/analyzeTrial.type';
import notYetProvided from '../../../assets/images/not-yet-provided.png';

interface GetShapefileColumnMapFieldsProps {
  shapefileGeometry: 'points' | 'polygons';
  shapefileType: 'as-applied' | 'yield';
  savedData?: FieldData | null;
  shapefileKeyColumnLabel?: string;
  trialUnit?: string;
}

export const getShapefileColumnMapInitialValues = ({
  shapefileGeometry,
  trialUnit,
  savedData,
  shapefileType,
}: GetShapefileColumnMapFieldsProps) => {
  let values: ShapefileColumnMapFields = {
    rate_column: '',
    rate_units: trialUnit ? trialUnit : '',
  };

  if (savedData) {
    values.rate_column = savedData?.rate_column || '';
    values.rate_units = savedData?.rate_units || (trialUnit ? trialUnit : '');

    // if the shapefile is points, we need extra column mappings. Most of the
    // functional chunks of this file do this same check and extend their
    // appropriate representations if so. This way, if the user gives us
    // polygons, they only have to fill out the rate column.
    if (shapefileGeometry === 'points') {
      values.swath_width_column = savedData.swath_width_column || '';
      values.swath_width_units = savedData.swath_width_units || 'ft';
      values.manual_swath_width = savedData.manual_swath_width || 0;
      values.distance_column = savedData.distance_column || '';
      values.distance_units = savedData.distance_units || 'ft';
      values.y_offset_column = savedData.y_offset_column || 'none';
      values.y_offset_units = savedData.y_offset_units || 'ft';
      values.manual_y_offset = savedData.manual_y_offset || 0;
      values.heading_column = savedData.heading_column || '';
      values.heading_units = 'Degrees';
    }

    // Add yield calibration fields if this is yield data
    if (shapefileType === 'yield') {
      values.has_calibrated_yield = savedData.has_calibrated_yield || false;
      values.calibrated_yield_value = savedData.calibrated_yield_value || 0;
      // Set calibrated_yield_units to the total yield units derived from rate_units
      values.calibrated_yield_units = getTotalYieldUnits(
        values.rate_units || '',
      );
    }

    return values;
  } else {
    let initialValues: ShapefileColumnMapFields = {
      rate_column: '',
      rate_units: trialUnit ? trialUnit : '',
    };

    if (shapefileGeometry === 'points') {
      initialValues = {
        ...initialValues,
        swath_width_column: '',
        swath_width_units: 'ft',
        manual_swath_width: undefined,
        distance_column: '',
        distance_units: 'ft',
        y_offset_column: '',
        y_offset_units: 'ft',
        manual_y_offset: undefined,
        heading_column: '',
        heading_units: 'Degrees',
      };
    }

    if (shapefileType === 'yield') {
      initialValues = {
        ...initialValues,
        has_calibrated_yield: false,
        calibrated_yield_value: 0,
        calibrated_yield_units: getTotalYieldUnits(
          initialValues.rate_units || '',
        ),
      };
    }

    return initialValues;
  }
};

export const getShapefileColumnMapValidator = ({
  shapefileGeometry,
  shapefileType,
}: GetShapefileColumnMapFieldsProps) => {
  const validationStructure: Record<string, any> = {
    rate_column: Yup.string().required('Rate column is required'),
    rate_units: Yup.string().required('Rate units are required'),
  };

  if (shapefileGeometry === 'points') {
    validationStructure.swath_width_column = Yup.string().required(
      'Swath width column is required',
    );
    validationStructure.manual_swath_width = Yup.number().when(
      'swath_width_column',
      {
        is: 'manual',
        then: Yup.number()
          .required('Swath width is required')
          .moreThan(0, 'Swath width must be greater than 0')
          .typeError('Swath width must be a number'),
      },
    );
    validationStructure.swath_width_units = Yup.string().when(
      'swath_width_column',
      {
        is: 'manual',
        then: Yup.string().required('Swath width units are required'),
      },
    );

    validationStructure.y_offset_column = Yup.string().required(
      'Y offset column is required',
    );
    validationStructure.y_offset_units = Yup.string().when('y_offset_column', {
      is: (val: string) => val !== 'none' && val !== 'manual',
      then: Yup.string().required('Y offset units are required'),
    });
    validationStructure.manual_y_offset = Yup.number().when('y_offset_column', {
      is: 'manual',
      then: Yup.number()
        .required('Y offset is required')
        .moreThan(0, 'Y offset must be greater than 0')
        .typeError('Y offset must be a number'),
    });
    validationStructure.y_offset_units = Yup.string().when('y_offset_column', {
      is: 'manual',
      then: Yup.string().required('Y offset units are required'),
    });

    validationStructure.heading_column = Yup.string().required(
      'Heading column is required',
    );
  }

  if (shapefileType === 'yield') {
    validationStructure.has_calibrated_yield = Yup.boolean();
    validationStructure.calibrated_yield_value = Yup.number().when(
      'has_calibrated_yield',
      {
        is: true,
        then: Yup.number()
          .required('Calibrated yield value is required')
          .moreThan(0, 'Total yield must be greater than 0')
          .typeError('Total yield must be a number'),
      },
    );
    validationStructure.calibrated_yield_units = Yup.string().when(
      'has_calibrated_yield',
      {
        is: true,
        then: Yup.string().required('Calibrated yield units are required'),
      },
    );
  }

  return Yup.object().shape(validationStructure);
};

export const getTotalYieldUnits = (yieldUnits: string): string => {
  // get the crop unit from the yield units, e.g. "bu/ac" -> "bu"
  // First try splitting on slash
  if (yieldUnits.includes('/')) {
    return yieldUnits.split('/')[0].trim();
  }

  // Then try splitting on "per"
  if (yieldUnits.toLowerCase().includes(' per ')) {
    return yieldUnits.toLowerCase().split(' per ')[0].trim();
  }

  // If no splits work, return as is
  return yieldUnits;
};

export const getShapefileColumnMapFields = ({
  shapefileType,
  shapefileGeometry,
  savedData,
}: GetShapefileColumnMapFieldsProps): FormElementPropsType[] => {
  let shapefileColumns: string[] = [];

  if (
    savedData &&
    'current_shapefile' in savedData &&
    savedData.current_shapefile !== undefined
  ) {
    shapefileColumns = savedData.current_shapefile.columns;
  }

  if (savedData) {
    shapefileColumns = [
      ...shapefileColumns,
      ...(savedData?.swath_width_column &&
      savedData.swath_width_column !== 'manual'
        ? [savedData.swath_width_column]
        : []),
      ...(savedData?.rate_column ? [savedData.rate_column] : []),
      ...(savedData?.heading_column ? [savedData.heading_column] : []),
      ...(savedData?.y_offset_column &&
      savedData.y_offset_column !== 'none' &&
      savedData.y_offset_column !== 'manual'
        ? [savedData.y_offset_column]
        : []),
      ...(savedData?.distance_column ? [savedData.distance_column] : []),
    ];
  }

  shapefileColumns = [...new Set(shapefileColumns)];
  let shapefileKeyColumnLabel;
  let rateUnitOptions = trialTypeOptions.units;
  let allowFreeFormRateUnit = false;
  if (shapefileType === 'as-applied') {
    shapefileKeyColumnLabel = 'Rate';
  } else if (shapefileType === 'yield') {
    rateUnitOptions = ['bu/ac', 'lb/ac'].map((unit) => getOption(unit, unit));
    if (
      savedData?.rate_units &&
      !rateUnitOptions.find((o) => o.value === savedData.rate_units)
    ) {
      // add the saved unit, if it is other
      rateUnitOptions.push({
        value: savedData.rate_units,
        label: savedData.rate_units,
      });
    }
    allowFreeFormRateUnit = true;
    shapefileKeyColumnLabel = 'Yield';
  }

  let fields: FormElementPropsType[] = [
    {
      element: 'sectionHeading',
      label: 'Match each property to a column name',
    },
    {
      element: 'horizontalInputPair',
      elements: [
        {
          element: 'select',
          name: 'rate_column',
          label: `${shapefileKeyColumnLabel}:`,
          options: getOptions(shapefileColumns),
        },
        {
          element: 'select',
          name: 'rate_units',
          label: 'Unit:',
          otherInputLabel: 'Enter unit:',
          options: rateUnitOptions,
          allowOther: allowFreeFormRateUnit,
        },
      ],
    },
  ];
  if (shapefileGeometry === 'points') {
    fields.push(
      {
        // This and the y-offset field are kind of fancy - the condition property is used so that we have two of each,
        // but only one is shown at a time. The first one is the default, and the second one is for when manual is selected.
        // This way, the dropdown is next to the units, until manual entry is selected, in which case the units jump down
        // to be next to the new manual input field.
        element: 'horizontalInputPair',
        condition: {
          field: 'swath_width_column',
          value: 'manual',
          operator: 'not-equals',
        },
        elements: [
          {
            element: 'select',
            name: 'swath_width_column',
            label: 'Swath width:',
            allowOther: false,
            options: [
              ...getOptions(shapefileColumns),
              { value: 'manual', label: 'Manually enter swath width' },
            ],
          },
          {
            element: 'radioGroup',
            name: 'swath_width_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            direction: 'column',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalSingleInput',
        condition: {
          field: 'swath_width_column',
          value: 'manual',
        },
        inputElement: {
          element: 'select',
          name: 'swath_width_column',
          label: 'Swath width:',
          allowOther: false,
          options: [
            ...getOptions(shapefileColumns),
            { value: 'manual', label: 'Manually enter swath width' },
          ],
        },
      },
      {
        element: 'horizontalInputPair',
        condition: {
          field: 'swath_width_column',
          value: 'manual',
        },
        elements: [
          {
            element: 'input',
            type: 'number',
            name: 'manual_swath_width',
            label: 'Enter swath width:',
          },
          {
            element: 'radioGroup',
            name: 'swath_width_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            direction: 'column',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalInputPair',
        elements: [
          {
            element: 'select',
            name: 'distance_column',
            label: 'Distance:',
            allowOther: false,
            options: getOptions(shapefileColumns),
          },
          {
            element: 'radioGroup',
            name: 'distance_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalInputPair',
        condition: {
          field: 'y_offset_column',
          value: 'manual',
          operator: 'not-equals',
        },
        elements: [
          {
            element: 'select',
            name: 'y_offset_column',
            label: 'Y offset:',
            allowOther: false,
            options: [
              ...getOptions(shapefileColumns),
              { value: 'none', label: 'The data has no y-offset' },
              { value: 'manual', label: 'Manually enter y-offset' },
            ],
          },
          {
            element: 'radioGroup',
            name: 'y_offset_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalSingleInput',
        condition: {
          field: 'y_offset_column',
          value: 'manual',
        },
        inputElement: {
          element: 'select',
          name: 'y_offset_column',
          label: 'Y offset:',
          allowOther: false,
          options: [
            ...getOptions(shapefileColumns),
            { value: 'none', label: 'The data has no y-offset' },
            { value: 'manual', label: 'Manually enter y-offset' },
          ],
        },
      },
      {
        element: 'horizontalInputPair',
        condition: {
          field: 'y_offset_column',
          value: 'manual',
        },
        elements: [
          {
            element: 'input',
            type: 'number',
            name: 'manual_y_offset',
            label: 'Enter Y offset:',
          },
          {
            element: 'radioGroup',
            name: 'y_offset_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalInputPair',
        elements: [
          {
            element: 'select',
            name: 'heading_column',
            label: 'Heading:',
            allowOther: false,
            options: getOptions(shapefileColumns),
          },
          {
            element: 'staticText',
            label: 'Unit:',
            name: 'heading_units',
            value: 'Degrees',
          },
        ],
      },
    );
  }

  // Add calibrated yield checkbox and inputs for yield data
  if (shapefileType === 'yield') {
    fields.push(
      {
        element: 'horizontalSingleInput',
        inputElement: {
          element: 'checkbox',
          name: 'has_calibrated_yield',
          label: 'I have a calibrated total yield',
          tooltip:
            'If provided, when creating your report, we will adjust your yield data so that its total yield matches this known total.',
        },
      },
      {
        element: 'horizontalInputPair',
        elements: [
          {
            element: 'input',
            type: 'number',
            name: 'calibrated_yield_value',
            label: 'Total yield:',
          },
          {
            element: 'staticText',
            label: 'Unit:',
            name: 'calibrated_yield_units',
            displayValue: '{{rate_units}}',
            transform: getTotalYieldUnits,
          },
        ],
        condition: {
          field: 'has_calibrated_yield',
          value: true,
        },
      },
    );
  }

  return fields;
};

const ShapefileColumnMapStep = ({
  shapefileType,
  fieldResultId,
  uploadedFiles,
  shapefileGeometry,
  imageUrl,
  ...rest
}: ShapefileColumnMapStepProps) => {
  const [canUpdate, setCanUpdate] = useState(true);
  const [currentFieldValues, setCurrentFieldValues] =
    useState<ShapefileColumnMapFields>(
      rest.initialValues as ShapefileColumnMapFields,
    );
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const shouldUpdate = (values: ShapefileColumnMapFields) => {
    // Check for 0 values in manual fields or total yield
    if (
      values.swath_width_column === 'manual' &&
      values.manual_swath_width === 0
    ) {
      return false;
    }
    if (values.y_offset_column === 'manual' && values.manual_y_offset === 0) {
      return false;
    }
    if (values.has_calibrated_yield && values.calibrated_yield_value === 0) {
      return false;
    }

    // set the fields we want to update on change for
    const columnKeys: (keyof ShapefileColumnMapFields)[] =
      shapefileGeometry === 'polygons'
        ? ['rate_column', 'rate_units']
        : [
            'rate_column',
            'rate_units',
            'swath_width_column',
            'swath_width_units',
            'distance_column',
            'distance_units',
            'y_offset_column',
            'y_offset_units',
            'heading_column',
          ];

    // check if all required fields are non-empty based on current state
    const allKeysNonEmpty = columnKeys.every((key) => {
      // For manual fields, we only need their units if they're actually being used
      if (key === 'swath_width_units') {
        return values.swath_width_column !== 'manual' || Boolean(values[key]);
      }
      if (key === 'y_offset_units') {
        return (
          (values.y_offset_column !== 'manual' &&
            values.y_offset_column !== 'none') ||
          Boolean(values[key])
        );
      }
      return Boolean(values[key]);
    });

    // Also check if manual values are valid when they're required
    if (values.swath_width_column === 'manual' && !values.manual_swath_width) {
      return false;
    }
    if (
      values.y_offset_column === 'manual' &&
      values.manual_y_offset === undefined
    ) {
      return false;
    }

    if (currentFieldValues !== values && allKeysNonEmpty) {
      // Check if anything has changed that would require an update
      const regularFieldsChanged = columnKeys.some((key) => {
        const changed = currentFieldValues[key] !== values[key];
        return changed;
      });

      const manualSwathChanged =
        values.swath_width_column === 'manual' &&
        values.manual_swath_width !== currentFieldValues.manual_swath_width;

      const manualYOffsetChanged =
        values.y_offset_column === 'manual' &&
        values.manual_y_offset !== currentFieldValues.manual_y_offset;

      // Check calibrated yield changes
      const calibratedYieldToggled =
        values.has_calibrated_yield !== currentFieldValues.has_calibrated_yield;

      const calibratedYieldChanged =
        values.has_calibrated_yield &&
        values.calibrated_yield_value !==
          currentFieldValues.calibrated_yield_value;

      // If calibrated yield is enabled, we need either:
      // 1. The checkbox was just toggled, OR
      // 2. The value changed (units are derived from rate_units)
      const calibratedYieldUpdate =
        calibratedYieldToggled || calibratedYieldChanged;

      return (
        regularFieldsChanged ||
        manualSwathChanged ||
        manualYOffsetChanged ||
        calibratedYieldUpdate
      );
    }
    return false;
  };

  const debouncedCallback = useDebouncedCallback((values) => {
    handleUpdate(values);
  }, 750);

  // Add a ref for the previous rate units value
  const prevRateUnits = useRef(currentFieldValues.rate_units);

  const handleUpdate = (values: ShapefileColumnMapFields) => {
    // If the new rate units value is "other", we delay the update until the user has stopped typing
    if (
      (values.rate_units === 'Other' || values.rate_units === '') &&
      prevRateUnits.current !== 'Other' &&
      prevRateUnits.current !== ''
    ) {
      if (values.rate_units?.trim() !== '' && values.rate_units !== 'Other') {
        updateColumnMap(values);
      }
    } else if (shouldUpdate(values)) {
      updateColumnMap(values);
    }

    // Update the previous rate units value
    prevRateUnits.current = values.rate_units;
  };

  const updateColumnMap = (values: ShapefileColumnMapFields) => {
    const columnMap = { ...values };
    setCurrentFieldValues(columnMap);
    if (canUpdate) {
      setTriggerUpdate(true);
      setCanUpdate(false);
    }
  };

  const handleUpdateComplete = () => {
    setCanUpdate(true);
    setTriggerUpdate(false);
  };

  return (
    <FormAndImageStep
      {...rest}
      imagePreviewElement={
        <ShapefileImagePreview
          initialImageUrl={imageUrl || notYetProvided}
          files={uploadedFiles}
          triggerUpdate={triggerUpdate}
          triggerUpdateCallback={handleUpdateComplete}
          columnMap={currentFieldValues}
          fieldResultId={fieldResultId}
          shapefileType={shapefileType}
          previewType="scaled"
          {...rest}
        />
      }
      valuesCallback={debouncedCallback}
    >
      {rest.children}
    </FormAndImageStep>
  );
};

export default ShapefileColumnMapStep;
